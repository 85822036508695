import React from 'react'
import { Card, Box, Typography, CardHeader, IconButton, Skeleton } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Media } from 'service/media'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { gSx } from 'styles/Theme'
import { getCalendarEvents, isZoomScheduled } from 'service/calendarApi'
import { getZoomSettings } from 'service/integrations/zoomApi'

/**
 * Connect zoom with ZoomAuthUrl.
 * OnSuccess, zoom redirects to ../zoom/callback
 * @returns 
 */

interface Props {
  hideIfConnected?: boolean
}
export default function ZoomCardForRecorder({ hideIfConnected }: Props) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { data: zoomSettings, isLoading } = useQuery({
    queryKey: ['getZoomSettings'], queryFn: getZoomSettings,
    enabled: queryClient.getQueryData(['getZoomSettings']) === undefined,
  })
  const isZoomConnected = !!zoomSettings?.zoom_user_id

  const { data: schedule } = useQuery({
    queryKey: ['getCalendarEvents'], queryFn: getCalendarEvents,
    enabled: queryClient.getQueryData(['getCalendarEvents']) === undefined,
  })
  const hasZoomScheduled = isZoomScheduled(schedule?.events)

  if (hideIfConnected && isZoomConnected)
    return null

  return (
    <Card sx={{ flexGrow: 1, margin: 0 }}>
      <CardHeader
        title={'Zoom'}
        action={
          <IconButton onClick={() => navigate(Path.ZoomSettings)}>
            <SettingsOutlinedIcon />
          </IconButton>
        }
      />

      {isLoading
        ? <Skeleton variant="rectangular" width={'100%'} height={25} />
        : isZoomConnected
          ? (
            <Box sx={gSx.CenterVertical}>
              <Box sx={gSx.Row}>
                <Box
                  component="img"
                  src={Media.zoomLogo}
                  alt="Zoom Connected"
                  sx={{ width: 30, marginRight: 2 }}
                />
                <Typography>
                  {`Signed in as ${zoomSettings.profile?.email}`}
                </Typography>
              </Box>
            </Box>
          ) : (
            <>
              {hasZoomScheduled &&
                <Typography>
                  Zoom meeting links have been found in your calendar
                </Typography>
              }
              <Typography sx={{ color: 'darkred' }}>
                To use Zoom, Finmate needs to connect to your Zoom account.
              </Typography>
            </>
          )
      }

    </Card >
  )
}
