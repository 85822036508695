import React from "react"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { getCalendarEvents, isZoomScheduled } from "service/calendarApi"
import ZoomCardForRecorder from "./ZoomCardForRecorder"
import { Box } from "@mui/material"
import { getZoomSettings } from "service/integrations/zoomApi"

const ZoomSetupAtHome = () => {
  const queryClient = useQueryClient()

  const { data: calEvents } = useQuery({
    queryKey: ['getCalendarEvents'], queryFn: getCalendarEvents,
    enabled: queryClient.getQueryData(['getCalendarEvents']) === undefined,
  })
  const hasZoomScheduled = isZoomScheduled(calEvents?.events)

  const { data: zoomSettings } = useQuery({
    queryKey: ['getZoomSettings'], queryFn: getZoomSettings,
    enabled: queryClient.getQueryData(['getZoomSettings']) === undefined,
  })
  const isZoomConnected = !!zoomSettings?.zoom_user_id

  if (hasZoomScheduled && !isZoomConnected)
    return <ZoomCardForRecorder />

  return null
}

export default ZoomSetupAtHome
